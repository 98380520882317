<template>
  <div>
    <div class="form-card">
      <div class="row">
        <div class="col-md-6">
          <div class="mb-3 ">
            <label for="contractsId" class="form-label">
              العقد
            </label>
            <select
              class="form-select form-control"
              id="contractsId"
              name="contractsId"
              v-model.number="contractsId"
            >
              <option
                v-for="item of contractsList"
                :key="item.id"
                :value="item"
                >{{ item.code }}</option
              >
            </select>
          </div>
        </div>
        <div class="col-md-6" v-if="contractsId">
          <div class="mb-3 ">
            <label for="clientsId" class="form-label">
              اسم العميل
            </label>
            <input
              class="form-control"
              id="clientsId"
              disabled
              v-model="contractsId.clientsId.name"
            />
          </div>
        </div>
        <div class="col-md-12 " v-if="contractsId">
          <div
            class=" text-right mb-3"
            dir="ltr"
            v-for="item of contractsId.surveysList"
            :key="item.surveysId.id"
          >
            {{ item.surveysId.name }} (0-{{ item.surveysId.ratingLimit }})
            <br />
            <InputNumber
              id="horizontal"
              v-model.number="item.rating"
              showButtons
              buttonLayout="horizontal"
              :step="1"
              min="0"
              :max="item.surveysId.ratingLimit"
              decrementButtonClass="p-button-danger"
              incrementButtonClass="p-button-success"
              incrementButtonIcon="pi pi-plus"
              decrementButtonIcon="pi pi-minus"
              :class="{
                'is-invalid': isInvalid && !body.rating,
              }"
              required
            />
          </div>
        </div>
      </div>

      <div class="mb-3 text-center">
        <Button
          label="حفظ"
          icon="pi pi-pencil"
          v-tooltip="'تعديل'"
          class="p-ml-2 p-button-info"
          @click="update()"
          :disabled="disabledAdd"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      contractsId: null,
      disabledAdd: false,
      contractsList: [],
      surveysList: [],
    };
  },
  methods: {
    update() {
      if (this.contractsId) {
        this.disabledAdd = true;
        this.$http
          .put(`contracts/surveysList/${this.contractsId.id}`, {
            surveysList: this.contractsId.surveysList,
          })
          .then(
            () => {
              this.$toast.add({
                severity: 'success',
                summary: 'تم بنجاح',
                detail: 'تم التعديل بنجاح',
                life: 3000,
              });

              this.disabledAdd = false;
              // this.$router.push('/admin/company');
            },
            (err) => {
              this.disabledAdd = false;
              this.$toast.add({
                severity: 'error',
                summary: 'هناك خطأ',
                detail: err.response.data.message,
                life: 3000,
              });
            },
          );
      } else {
        this.$toast.add({
          severity: 'error',
          summary: 'هناك خطأ',
          detail: 'يرجي اضافة الحقول المطلوبه',
          life: 3000,
        });
      }
    },
  },
  created() {
    if (!this.$checkRoles('secretarySurveysVeiw')) {
      this.$router.push('/admin/');
    }

    this.$http
      .post(`contracts/search`, {
        relations: ['surveysList'],
      })
      .then((res) => {
        this.contractsList = res.data;
      });
    this.$http.get(`surveys`).then((res) => {
      this.surveysList = res.data;
    });
  },
  watch: {
    contractsId(val) {
      if (val && val.surveysList) {
        // list = [];
        for (const item of this.surveysList) {
          if (!val.surveysList.find((el) => el.surveysId.id == item.id)) {
            val.surveysList.push({
              surveysId: item,
              rating: 0,
            });
          }
        }
      }
    },
  },
};
</script>
